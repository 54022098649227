/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import Slugs from "./src/data/slugs/slugs.json";
import { navigate } from "gatsby";

const DEFAULT_LANGUAGE = "en";
const LANGUAGES = ["es", "pt", "en", "de", "fr", "it", "tr"];

export const onPreRouteUpdate = ({ location, prevLocation }) => {
  const redirectUrl = getRedirectUrl(location);
  console.log(location);
  if (location.pathname === '/join/' && location.search.includes('?eventSecret')) {
    let eventSecret = location.search.split('=')[1];
    window.sessionStorage.setItem("eventSecret", eventSecret);
  }

  if (redirectUrl) {
    navigate(redirectUrl);
  }
};

// This should fix scroll not scrolling to top on page change due to animations 
// https://janessagarrow.com/blog/gatsby-framer-motion-page-transitions/
export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition
}) => {
  window.scroll(0, 0);
  return true;
  const TRANSITION_DELAY = 0.3 * 1000 * 2
  window.setTimeout(() => window.scrollTo(0, 0), TRANSITION_DELAY)
  return;
  // transition duration from `layout.js` * 1000 to get time in ms
  // * 2 for exit + enter animation
  // if it's a "normal" route
  if (location.action === "PUSH") {
    window.setTimeout(() => window.scrollTo(0, 0), TRANSITION_DELAY)
  }
  // if we used the browser's forwards or back button
  else {
    const savedPosition = getSavedScrollPosition(location) || [0, 0]
    window.setTimeout(() => window.scrollTo(...savedPosition), TRANSITION_DELAY)
  }
  return false
}
const getLanguageFromSplitedUrl = (splittedUrl) => {
  var browserSpecificLocale = window.navigator.languages.filter(item => item.includes("-"))
  var locale = window.navigator.userLanguagee || window.navigator.language;
  if (browserSpecificLocale.length > 0) {
    locale = browserSpecificLocale[0];
  }

  var navLanguage = locale.split("-");

  let language = navLanguage[0] || DEFAULT_LANGUAGE;

  if (splittedUrl.length >= 1) {
    if (LANGUAGES.includes(splittedUrl[0])) {
      language = splittedUrl[0];
    }
  }

  if (!LANGUAGES.includes(language)) {
    language = DEFAULT_LANGUAGE;
  }
  return language;
};

const getSlugsOfLanguage = (language) => {
  return Slugs.find((item) => item.language === language).slugs;
};

const buildRedirectURL = (lang, path = null) => {
  if (!path) {
    return `/${lang}/`;
  } else {
    return `/${lang}/${path}/`;
  }
};

const getSlugKeyFromLanguages = (languages, slugValue) => {
  let slugKey = null;
  languages.forEach((language) => {
    let currentSlugs = getSlugsOfLanguage(language);
    Object.keys(currentSlugs).forEach((key) => {
      if (currentSlugs[key] === slugValue) {
        slugKey = key;
      }
    });
  });
  slugKey = !!slugKey ? slugKey : "notFoundPage";

  return slugKey;
};

const getRedirectUrl = (loc) => {
  let redirectUrl = null;

  let splittedURL = loc.pathname.split("/").filter((item) => item !== "");
  let userLanguage = getLanguageFromSplitedUrl(splittedURL);
  if (loc.pathname.includes("enterprise") && (!loc.pathname.includes("en") && !loc.pathname.includes("de"))) {
    return "/en/enterprise"
  }

  if (splittedURL.length === 0) {
    return buildRedirectURL(userLanguage);
  }

  if (LANGUAGES.includes(splittedURL[0])) {
    let existInLanguage = null;
    let slugsOfLanguages = getSlugsOfLanguage(userLanguage);
    if (splittedURL.length > 1) {
      Object.keys(slugsOfLanguages).forEach((key) => {
        if (slugsOfLanguages[key] === splittedURL[1]) {
          existInLanguage = true;
        }
      });
      if (!existInLanguage) {
        const restOfLanguages = LANGUAGES.filter(
          (item) => item !== userLanguage
        );
        let slugKey = getSlugKeyFromLanguages(restOfLanguages, splittedURL[1]);
        redirectUrl = buildRedirectURL(userLanguage, slugsOfLanguages[slugKey]);
      }
    }
  } else {
    let slugKey = getSlugKeyFromLanguages(LANGUAGES, splittedURL[0]);
    let languageSlugs = getSlugsOfLanguage(userLanguage);
    if (slugKey === "notFoundPage" && splittedURL.length === 1) {
      redirectUrl = "/";
    } else {
      redirectUrl = buildRedirectURL(userLanguage, languageSlugs[slugKey]);
    }
  }
  if (!!loc.search && !!redirectUrl) {
    redirectUrl = redirectUrl.slice(0, -1);

    return `${redirectUrl}${loc.search}`;
  }
  return redirectUrl;
};
